import React, { Component, Fragment } from "react"

import { withAuth0 } from "@auth0/auth0-react"
import Alert from "react-bootstrap/Alert"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import { FormattedMessage } from "react-intl"

import Space from "./space"

class IdentityId extends React.Component {
  constructor(props) {
    super(props)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleStartVerification = this.handleStartVerification.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.handleVerify = this.handleVerify.bind(this)
    this.state = {
      status: "view",
      idStatus: this.props.identity.idStatus,
      salutation: this.props.identity.salutation,
      firstName: this.props.identity.firstName,
      lastName: this.props.identity.lastName,
      birthdate: this.props.identity.birthdate,
      nationality: this.props.identity.nationality,
    }
  }

  handleCancel(e) {
    e.preventDefault()
    this.setState({
      status: "view",
      alert: null,
      salutation: this.state.salutation_original,
      firstName: this.state.firstName_original,
      lastName: this.state.lastName_original,
      birthdate: this.state.birthdate_original,
      nationality: this.state.nationality_original,
    })
  }

  handleEdit(e) {
    e.preventDefault()
    this.setState({
      status: "edit",
      alert: null,
      salutation_original: this.state.salutation,
      firstName_original: this.state.firstName,
      lastName_original: this.state.lastName,
      birthdate_original: this.state.birthdate,
      nationality_original: this.state.nationality,
    })
  }

  handleInputChange(e) {
    const target = e.target
    const name = target.name
    const value = target.type === "checkbox" ? target.checked : target.value

    this.setState({ [name]: value })
  }

  handleStartVerification(e) {
    e.preventDefault()
    this.setState(
      {
        status: "start_verification",
        alert: null,
      },
      () => {
        this.props.auth0
          .getAccessTokenSilently({
            audience: process.env.GATSBY_AUTH0_AUDIENCE,
            scope: process.env.GATSBY_AUTH0_SCOPE,
          })
          .then(accessToken => {
            const url = new URL(
              "/identity/startIdVerification",
              process.env.GATSBY_API_URL
            )
            const params = {}
            Object.keys(params).forEach(key =>
              url.searchParams.append(key, params[key])
            )
            fetch(url, {
              method: "POST",
              headers: { Authorization: `Bearer ${accessToken}` },
            })
              .then(response => {
                if (response.ok) {
                  response.text().then(text => {
                    this.setState({
                      status: "view",
                      idStatus: "Verifying (1)",
                    })
                  })
                } else {
                  response.text().then(text => {
                    this.setState({
                      status: "view",
                      alert: text,
                    })
                  })
                }
              })
              .catch(error => {
                this.setState({
                  status: "view",
                  alert: error.message,
                })
              })
          })
      }
    )
  }

  handleUpdate(e) {
    e.preventDefault()
    this.setState(
      {
        status: "update",
        alert: null,
      },
      () => {
        this.props.auth0
          .getAccessTokenSilently({
            audience: process.env.GATSBY_AUTH0_AUDIENCE,
            scope: process.env.GATSBY_AUTH0_SCOPE,
          })
          .then(accessToken => {
            const url = new URL(
              "/identity/updateId",
              process.env.GATSBY_API_URL
            )
            const params = {}
            params.salutation = this.state.salutation
            params.firstName = this.state.firstName
            params.lastName = this.state.lastName
            params.birthdate = this.state.birthdate
            params.nationality = this.state.nationality
            Object.keys(params).forEach(key =>
              url.searchParams.append(key, params[key])
            )
            fetch(url, {
              method: "POST",
              headers: { Authorization: `Bearer ${accessToken}` },
            }).then(response => {
              if (response.ok) {
                response.text().then(text => {
                  this.setState({
                    status: "view",
                  })
                })
              } else {
                response.text().then(text => {
                  this.setState({
                    status: "edit",
                    alert: text,
                  })
                })
              }
            })
          })
      }
    )
  }

  handleVerify(e) {
    e.preventDefault()
    this.setState(
      {
        status: "verify",
        alert: null,
      },
      () => {
        this.props.auth0
          .getAccessTokenSilently({
            audience: process.env.GATSBY_AUTH0_AUDIENCE,
            scope: process.env.GATSBY_AUTH0_SCOPE,
          })
          .then(accessToken => {
            const url = new URL(
              "/identity/verifyId",
              process.env.GATSBY_API_URL
            )
            const params = {}
            params.verificationCode = this.state.verificationCode
            Object.keys(params).forEach(key =>
              url.searchParams.append(key, params[key])
            )
            fetch(url, {
              method: "POST",
              headers: { Authorization: `Bearer ${accessToken}` },
            })
              .then(response => {
                if (response.ok) {
                  response.text().then(text => {
                    this.setState({
                      status: "view",
                      idStatus: "Verified",
                    })
                  })
                } else {
                  response.text().then(text => {
                    this.setState({
                      status: "view",
                      alert: text,
                    })
                  })
                }
              })
              .catch(error => {
                this.setState({
                  status: "view",
                  alert: error.message,
                })
              })
          })
      }
    )
  }

  render() {
    return (
      <>
        {this.state.idStatus == "New" && (
          <Alert variant="info">
            <FormattedMessage id={"Contact us to enable ID verification."} />
          </Alert>
        )}
        {this.state.idStatus == "Verifying (1)" && (
          <Alert variant="info">
            <FormattedMessage
              id={"We are verifying your ID information. (1)"}
            />
          </Alert>
        )}
        {this.state.idStatus == "Verifying (2)" && (
          <Alert variant="info">
            <FormattedMessage
              id={"We are verifying your ID information. (2)"}
            />
          </Alert>
        )}
        {this.state.idStatus == "Verified" && (
          <Alert variant="info">
            <FormattedMessage id={"ID information verified successfully."} />
          </Alert>
        )}
        {this.state.idStatus == "Not Verified" && (
          <Alert variant="info">
            <FormattedMessage id={"Contact us to enable ID verification."} />
          </Alert>
        )}
        {this.state.alert != null && (
          <Alert variant="danger">
            <FormattedMessage id={this.state.alert} />
          </Alert>
        )}
        <Form>
          <Form.Group>
            <Form.Label>
              <FormattedMessage id={"Salutation"} />
            </Form.Label>
            <Form.Control
              name="salutation"
              as="select"
              value={this.state.salutation}
              disabled={this.state.status != "edit"}
              onChange={this.handleInputChange}
            >
              <option></option>
              <FormattedMessage id={"Mr."}>
                {message => <option value="Mr.">{message}</option>}
              </FormattedMessage>
              <FormattedMessage id={"Ms."}>
                {message => <option value="Ms.">{message}</option>}
              </FormattedMessage>
              <FormattedMessage id={"Mrs."}>
                {message => <option value="Mrs.">{message}</option>}
              </FormattedMessage>
              <FormattedMessage id={"Dr."}>
                {message => <option value="Dr.">{message}</option>}
              </FormattedMessage>
              <FormattedMessage id={"Prof."}>
                {message => <option value="Prof.">{message}</option>}
              </FormattedMessage>
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              <FormattedMessage id={"First Name"} />
            </Form.Label>
            <Form.Control
              name="firstName"
              type="text"
              value={this.state.firstName}
              disabled={this.state.status != "edit"}
              onChange={this.handleInputChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>
              <FormattedMessage id={"Last Name"} />
            </Form.Label>
            <Form.Control
              name="lastName"
              type="text"
              value={this.state.lastName}
              disabled={this.state.status != "edit"}
              onChange={this.handleInputChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>
              <FormattedMessage id={"Birthdate"} />
            </Form.Label>
            <Form.Control
              name="birthdate"
              type="date"
              value={this.state.birthdate}
              disabled={this.state.status != "edit"}
              onChange={this.handleInputChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>
              <FormattedMessage id={"Nationality"} />
            </Form.Label>
            <Form.Control
              name="nationality"
              type="text"
              value={this.state.nationality}
              disabled={this.state.status != "edit"}
              onChange={this.handleInputChange}
            />
          </Form.Group>
          {this.state.idStatus == "Verifying (2)" && (
            <Form.Group>
              <Form.Label>
                <FormattedMessage id={"Verification Code"} />
              </Form.Label>
              <Form.Control
                name="verificationCode"
                type="text"
                value={this.state.verificationCode}
                disabled={this.state.status != "view"}
                onChange={this.handleInputChange}
              />
            </Form.Group>
          )}
          {this.state.status == "view" && this.state.idStatus == "New" && (
            <>
              <Button variant="primary" onClick={this.handleEdit}>
                <FormattedMessage id={"Edit"} />
              </Button>
              <Space />
              <Button variant="warning" disabled>
                <FormattedMessage id={"Verify"} />
              </Button>
            </>
          )}
          {this.state.status == "view" && this.state.idStatus == "Ready" && (
            <>
              <Button variant="primary" onClick={this.handleEdit}>
                <FormattedMessage id={"Edit"} />
              </Button>
              <Space />
              <Button variant="warning" onClick={this.handleStartVerification}>
                <FormattedMessage id={"Verify"} />
              </Button>
            </>
          )}
          {this.state.status == "start_verification" && (
            <>
              <Button variant="primary" disabled>
                <FormattedMessage id={"Edit"} />
              </Button>
              <Space />
              <Button variant="warning" disabled>
                <FormattedMessage id={"Verify"} />
              </Button>
            </>
          )}
          {this.state.status == "view" &&
            this.state.idStatus == "Verifying (1)" && (
              <>
                <Button variant="primary" disabled>
                  <FormattedMessage id={"Edit"} />
                </Button>
                <Space />
                <Button variant="warning" disabled>
                  <FormattedMessage id={"Verify"} />
                </Button>
              </>
            )}
          {this.state.status == "view" &&
            this.state.idStatus == "Verifying (2)" && (
              <>
                <Button variant="primary" disabled>
                  <FormattedMessage id={"Edit"} />
                </Button>
                <Space />
                <Button variant="warning" onClick={this.handleVerify}>
                  <FormattedMessage id={"Verify"} />
                </Button>
              </>
            )}
          {this.state.status == "verify" && (
            <>
              <Button variant="primary" disabled>
                <FormattedMessage id={"Edit"} />
              </Button>
              <Space />
              <Button variant="warning" disabled>
                <FormattedMessage id={"Verify"} />
              </Button>
            </>
          )}
          {this.state.status == "view" && this.state.idStatus == "Verified" && (
            <>
              <Button variant="primary" disabled>
                <FormattedMessage id={"Edit"} />
              </Button>
              <Space />
              <Button variant="warning" disabled>
                <FormattedMessage id={"Verify"} />
              </Button>
            </>
          )}
          {this.state.status == "view" &&
            this.state.idStatus == "Not Verified" && (
              <>
                <Button variant="primary" disabled>
                  <FormattedMessage id={"Edit"} />
                </Button>
                <Space />
                <Button variant="warning" disabled>
                  <FormattedMessage id={"Verify"} />
                </Button>
              </>
            )}
          {this.state.status == "edit" && (
            <>
              <Button variant="primary" onClick={this.handleUpdate}>
                <FormattedMessage id={"Update"} />
              </Button>
              <Space />
              <Button variant="secondary" onClick={this.handleCancel}>
                <FormattedMessage id={"Cancel"} />
              </Button>
            </>
          )}
          {this.state.status == "update" && (
            <>
              <Button variant="primary" disabled>
                <FormattedMessage id={"Update"} />
              </Button>
              <Space />
              <Button variant="secondary" disabled>
                <FormattedMessage id={"Cancel"} />
              </Button>
            </>
          )}
        </Form>
      </>
    )
  }
}

export default withAuth0(IdentityId)
