import React from "react"

import { withAuthenticationRequired } from "@auth0/auth0-react"
import { useIntl } from "react-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"
import IdentityNavigation from "../components/identity_navigation"
import Identity from "../components/identity"

const MyIdentityPage = ({ location }) => {
  const intl = useIntl()
  const pageTitle = intl.formatMessage({ id: "My Identity" })
  return (
    <Layout pageTitle={pageTitle} location={location}>
      <SEO title={pageTitle} />
      <IdentityNavigation location={location} />
      <Identity />
    </Layout>
  )
}

export default withAuthenticationRequired(MyIdentityPage)
