import React, { Component, Fragment } from "react"

import { withAuth0 } from "@auth0/auth0-react"
import Alert from "react-bootstrap/Alert"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import { FormattedMessage } from "react-intl"

import Space from "./space"

class IdentityBusiness extends React.Component {
  constructor(props) {
    super(props)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleStartVerification = this.handleStartVerification.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.handleVerify = this.handleVerify.bind(this)
    this.state = {
      status: "view",
      businessStatus: this.props.identity.businessStatus,
      businessName: this.props.identity.businessName,
      businessNumber: this.props.identity.businessNumber,
      businessCountry: this.props.identity.businessCountry,
    }
  }

  handleCancel(e) {
    e.preventDefault()
    this.setState({
      status: "view",
      alert: null,
      businessName: this.state.businessName_original,
      businessNumber: this.state.businessNumber_original,
      businessCountry: this.state.businessCountry_original,
    })
  }

  handleEdit(e) {
    e.preventDefault()
    this.setState({
      status: "edit",
      alert: null,
      businessName_original: this.state.businessName,
      businessNumber_original: this.state.businessNumber,
      businessCountry_original: this.state.businessCountry,
    })
  }

  handleInputChange(e) {
    const target = e.target
    const name = target.name
    const value = target.type === "checkbox" ? target.checked : target.value

    this.setState({ [name]: value })
  }

  handleStartVerification(e) {
    e.preventDefault()
    this.setState(
      {
        status: "start_verification",
        alert: null,
      },
      () => {
        this.props.auth0
          .getAccessTokenSilently({
            audience: process.env.GATSBY_AUTH0_AUDIENCE,
            scope: process.env.GATSBY_AUTH0_SCOPE,
          })
          .then(accessToken => {
            const url = new URL(
              "/identity/startBusinessVerification",
              process.env.GATSBY_API_URL
            )
            const params = {}
            Object.keys(params).forEach(key =>
              url.searchParams.append(key, params[key])
            )
            fetch(url, {
              method: "POST",
              headers: { Authorization: `Bearer ${accessToken}` },
            })
              .then(response => {
                if (response.ok) {
                  response.text().then(text => {
                    this.setState({
                      status: "view",
                      businessStatus: "Verifying (1)",
                    })
                  })
                } else {
                  response.text().then(text => {
                    this.setState({
                      status: "view",
                      alert: text,
                    })
                  })
                }
              })
              .catch(error => {
                this.setState({
                  status: "view",
                  alert: error.message,
                })
              })
          })
      }
    )
  }

  handleUpdate(e) {
    e.preventDefault()
    this.setState(
      {
        status: "update",
        alert: null,
      },
      () => {
        this.props.auth0
          .getAccessTokenSilently({
            audience: process.env.GATSBY_AUTH0_AUDIENCE,
            scope: process.env.GATSBY_AUTH0_SCOPE,
          })
          .then(accessToken => {
            const url = new URL(
              "/identity/updateBusiness",
              process.env.GATSBY_API_URL
            )
            const params = {}
            params.businessName = this.state.businessName
            params.businessNumber = this.state.businessNumber
            params.businessCountry = this.state.businessCountry
            Object.keys(params).forEach(key =>
              url.searchParams.append(key, params[key])
            )
            fetch(url, {
              method: "POST",
              headers: { Authorization: `Bearer ${accessToken}` },
            }).then(response => {
              if (response.ok) {
                response.text().then(text => {
                  this.setState({
                    status: "view",
                  })
                })
              } else {
                response.text().then(text => {
                  this.setState({
                    status: "edit",
                    alert: text,
                  })
                })
              }
            })
          })
      }
    )
  }

  handleVerify(e) {
    e.preventDefault()
    this.setState(
      {
        status: "verify",
        alert: null,
      },
      () => {
        this.props.auth0
          .getAccessTokenSilently({
            audience: process.env.GATSBY_AUTH0_AUDIENCE,
            scope: process.env.GATSBY_AUTH0_SCOPE,
          })
          .then(accessToken => {
            const url = new URL(
              "/identity/verifyBusiness",
              process.env.GATSBY_API_URL
            )
            const params = {}
            params.verificationCode = this.state.verificationCode
            Object.keys(params).forEach(key =>
              url.searchParams.append(key, params[key])
            )
            fetch(url, {
              method: "POST",
              headers: { Authorization: `Bearer ${accessToken}` },
            })
              .then(response => {
                if (response.ok) {
                  response.text().then(text => {
                    this.setState({
                      status: "view",
                      businessStatus: "Verified",
                    })
                  })
                } else {
                  response.text().then(text => {
                    this.setState({
                      status: "view",
                      alert: text,
                    })
                  })
                }
              })
              .catch(error => {
                this.setState({
                  status: "view",
                  alert: error.message,
                })
              })
          })
      }
    )
  }

  render() {
    return (
      <>
        {this.state.businessStatus == "New" && (
          <Alert variant="info">
            <FormattedMessage
              id={"Contact us to enable business verification."}
            />
          </Alert>
        )}
        {this.state.businessStatus == "Verifying (1)" && (
          <Alert variant="info">
            <FormattedMessage
              id={"We are verifying your business information. (1)"}
            />
          </Alert>
        )}
        {this.state.businessStatus == "Verifying (2)" && (
          <Alert variant="info">
            <FormattedMessage
              id={"We are verifying your business information. (2)"}
            />
          </Alert>
        )}
        {this.state.businessStatus == "Verified" && (
          <Alert variant="info">
            <FormattedMessage
              id={"Business information verified successfully."}
            />
          </Alert>
        )}
        {this.state.businessStatus == "Not Verified" && (
          <Alert variant="info">
            <FormattedMessage
              id={"Contact us to enable business verification."}
            />
          </Alert>
        )}
        {this.state.alert != null && (
          <Alert variant="danger">
            <FormattedMessage id={this.state.alert} />
          </Alert>
        )}
        <Form>
          <Form.Group>
            <Form.Label>
              <FormattedMessage id={"Business Name"} />
            </Form.Label>
            <Form.Control
              name="businessName"
              type="text"
              value={this.state.businessName}
              disabled={this.state.status != "edit"}
              onChange={this.handleInputChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>
              <FormattedMessage id={"Business Number"} />
            </Form.Label>
            <Form.Control
              name="businessNumber"
              type="text"
              value={this.state.businessNumber}
              disabled={this.state.status != "edit"}
              onChange={this.handleInputChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>
              <FormattedMessage id={"Business Country"} />
            </Form.Label>
            <Form.Control
              name="businessCountry"
              type="text"
              value={this.state.businessCountry}
              disabled={this.state.status != "edit"}
              onChange={this.handleInputChange}
            />
          </Form.Group>
          {this.state.businessStatus == "Verifying (2)" && (
            <Form.Group>
              <Form.Label>
                <FormattedMessage id={"Verification Code"} />
              </Form.Label>
              <Form.Control
                name="verificationCode"
                type="text"
                value={this.state.verificationCode}
                disabled={this.state.status != "view"}
                onChange={this.handleInputChange}
              />
            </Form.Group>
          )}
          {this.state.status == "view" && this.state.businessStatus == "New" && (
            <>
              <Button variant="primary" onClick={this.handleEdit}>
                <FormattedMessage id={"Edit"} />
              </Button>
              <Space />
              <Button variant="warning" disabled>
                <FormattedMessage id={"Verify"} />
              </Button>
            </>
          )}
          {this.state.status == "view" && this.state.businessStatus == "Ready" && (
            <>
              <Button variant="primary" onClick={this.handleEdit}>
                <FormattedMessage id={"Edit"} />
              </Button>
              <Space />
              <Button variant="warning" onClick={this.handleStartVerification}>
                <FormattedMessage id={"Verify"} />
              </Button>
            </>
          )}
          {this.state.status == "start_verification" && (
            <>
              <Button variant="primary" disabled>
                <FormattedMessage id={"Edit"} />
              </Button>
              <Space />
              <Button variant="warning" disabled>
                <FormattedMessage id={"Verify"} />
              </Button>
            </>
          )}
          {this.state.status == "view" &&
            this.state.businessStatus == "Verifying (1)" && (
              <>
                <Button variant="primary" disabled>
                  <FormattedMessage id={"Edit"} />
                </Button>
                <Space />
                <Button variant="warning" disabled>
                  <FormattedMessage id={"Verify"} />
                </Button>
              </>
            )}
          {this.state.status == "view" &&
            this.state.businessStatus == "Verifying (2)" && (
              <>
                <Button variant="primary" disabled>
                  <FormattedMessage id={"Edit"} />
                </Button>
                <Space />
                <Button variant="warning" onClick={this.handleVerify}>
                  <FormattedMessage id={"Verify"} />
                </Button>
              </>
            )}
          {this.state.status == "verify" && (
            <>
              <Button variant="primary" disabled>
                <FormattedMessage id={"Edit"} />
              </Button>
              <Space />
              <Button variant="warning" disabled>
                <FormattedMessage id={"Verify"} />
              </Button>
            </>
          )}
          {this.state.status == "view" &&
            this.state.businessStatus == "Verified" && (
              <>
                <Button variant="primary" disabled>
                  <FormattedMessage id={"Edit"} />
                </Button>
                <Space />
                <Button variant="warning" disabled>
                  <FormattedMessage id={"Verify"} />
                </Button>
              </>
            )}
          {this.state.status == "view" &&
            this.state.businessStatus == "Not Verified" && (
              <>
                <Button variant="primary" disabled>
                  <FormattedMessage id={"Edit"} />
                </Button>
                <Space />
                <Button variant="warning" disabled>
                  <FormattedMessage id={"Verify"} />
                </Button>
              </>
            )}
          {this.state.status == "edit" && (
            <>
              <Button variant="primary" onClick={this.handleUpdate}>
                <FormattedMessage id={"Update"} />
              </Button>
              <Space />
              <Button variant="secondary" onClick={this.handleCancel}>
                <FormattedMessage id={"Cancel"} />
              </Button>
            </>
          )}
          {this.state.status == "update" && (
            <>
              <Button variant="primary" disabled>
                <FormattedMessage id={"Update"} />
              </Button>
              <Space />
              <Button variant="secondary" disabled>
                <FormattedMessage id={"Cancel"} />
              </Button>
            </>
          )}
        </Form>
      </>
    )
  }
}

export default withAuth0(IdentityBusiness)
