import React, { Component, Fragment } from "react"

import { withAuth0 } from "@auth0/auth0-react"
import Alert from "react-bootstrap/Alert"
import Card from "react-bootstrap/Card"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Table from "react-bootstrap/Table"
import { FormattedMessage } from "react-intl"
import { useIntl } from "react-intl"

import Error from "./error"
import IdentityAddress from "./identity_address"
import IdentityBank from "./identity_bank"
import IdentityBusiness from "./identity_business"
import IdentityEmail from "./identity_email"
import IdentityId from "./identity_id"
import IdentityMobile from "./identity_mobile"
import Loading from "./loading"
import Separator from "./separator"

class Identity extends React.Component {
  constructor(props) {
    super(props)
    this.refresh = this.refresh.bind(this)
    this.state = { loaded: false, error: null, data: null }
  }

  componentDidMount() {
    this.refresh()
  }

  refresh() {
    this.props.auth0
      .getAccessTokenSilently({
        audience: process.env.GATSBY_AUTH0_AUDIENCE,
        scope: process.env.GATSBY_AUTH0_SCOPE,
      })
      .then(accessToken => {
        const url = new URL("/identity/identity", process.env.GATSBY_API_URL)
        const params = {}
        Object.keys(params).forEach(key =>
          url.searchParams.append(key, params[key])
        )
        fetch(url, {
          method: "GET",
          headers: { Authorization: `Bearer ${accessToken}` },
        })
          .then(response => {
            if (response.ok) {
              response.json().then(json => {
                this.setState({ loaded: true, data: json })
              })
            } else {
              response.text().then(text => {
                this.setState({ loaded: true, error: text })
              })
            }
          })
          .catch(error => {
            this.setState({
              loaded: true,
              error: error,
            })
          })
      })
  }

  render() {
    const { loaded, error, data } = this.state
    if (!loaded) {
      return <Loading />
    } else if (error) {
      return <Error error={error} />
    } else {
      return (
        <>
          <Row className="mb-3">
            <Col className="col-auto">
              <Card className="border-dark" style={{ width: "27rem" }}>
                <Card.Header className="text-white bg-dark">
                  <FormattedMessage id={"My Identity"} />
                  <Separator />
                  <FormattedMessage id={"Email"} />
                </Card.Header>
                <Card.Body>
                  <IdentityEmail identity={this.state.data} />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="col-auto">
              <Card className="border-dark" style={{ width: "27rem" }}>
                <Card.Header className="text-white bg-dark">
                  <FormattedMessage id={"My Identity"} />
                  <Separator />
                  <FormattedMessage id={"Mobile"} />
                </Card.Header>
                <Card.Body>
                  <IdentityMobile identity={this.state.data} />
                </Card.Body>
              </Card>
            </Col>
            <Col className="col-auto">
              <Alert variant="secondary" style={{ width: "18rem" }}>
                <FormattedMessage id={"Identity.Mobile Verification"} />
              </Alert>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="col-auto">
              <Card className="border-dark" style={{ width: "27rem" }}>
                <Card.Header className="text-white bg-dark">
                  <FormattedMessage id={"My Identity"} />
                  <Separator />
                  <FormattedMessage id={"ID"} />
                </Card.Header>
                <Card.Body>
                  <IdentityId identity={this.state.data} />
                </Card.Body>
              </Card>
            </Col>
            <Col className="col-auto">
              <Alert variant="secondary" style={{ width: "18rem" }}>
                <FormattedMessage id={"Identity.ID Verification"} />
              </Alert>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="col-auto">
              <Card className="border-dark" style={{ width: "27rem" }}>
                <Card.Header className="text-white bg-dark">
                  <FormattedMessage id={"My Identity"} />
                  <Separator />
                  <FormattedMessage id={"Bank"} />
                </Card.Header>
                <Card.Body>
                  <IdentityBank identity={this.state.data} />
                </Card.Body>
              </Card>
            </Col>
            <Col className="col-auto">
              <Alert variant="secondary" style={{ width: "18rem" }}>
                <FormattedMessage id={"Identity.Bank Verification"} />
              </Alert>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="col-auto">
              <Card className="border-dark" style={{ width: "27rem" }}>
                <Card.Header className="text-white bg-dark">
                  <FormattedMessage id={"My Identity"} />
                  <Separator />
                  <FormattedMessage id={"Address"} />
                </Card.Header>
                <Card.Body>
                  <IdentityAddress identity={this.state.data} />
                </Card.Body>
              </Card>
            </Col>
            <Col className="col-auto">
              <Alert variant="secondary" style={{ width: "18rem" }}>
                <FormattedMessage id={"Identity.Address Verification"} />
              </Alert>
            </Col>
          </Row>
          <Row>
            <Col className="col-auto">
              <Card className="border-dark" style={{ width: "27rem" }}>
                <Card.Header className="text-white bg-dark">
                  <FormattedMessage id={"My Identity"} />
                  <Separator />
                  <FormattedMessage id={"Business"} />
                </Card.Header>
                <Card.Body>
                  <IdentityBusiness identity={this.state.data} />
                </Card.Body>
              </Card>
            </Col>
            <Col className="col-auto">
              <Alert variant="secondary" style={{ width: "18rem" }}>
                <FormattedMessage id={"Identity.Business Verification"} />
              </Alert>
            </Col>
          </Row>
        </>
      )
    }
  }
}

export default withAuth0(Identity)
